.history-section {
    max-width: 30%!important;
    overflow-y: auto;
    max-height: 60vh;
    padding: 1rem;
    @extend .d-flex, .flex-column;
}

.history-section::-webkit-scrollbar {
    width: 0.3rem;
}
  
.history-section::-webkit-scrollbar-track {
    background-color: #e0e0e0;
}

.history-section::-webkit-scrollbar-thumb {
    background-color: #0b294b;
}

.tg-workflow-text{
    letter-spacing: 0.04rem;
    display:block;
    width:100%;
    word-break:break-all;
}

.tg-approver {
    font-weight: 400;
    margin: 0.25rem 0;
    font-size: 0.8rem;
    @extend .tg-workflow-text;
}

.tg-approver-byline {
    font-weight: 400;
    margin: 0.25rem 0;
    font-size: 0.6rem;
    @extend .tg-workflow-text;
}
  
.tg-approver-employee-code {
    font-weight: 500;
    color: #51585e;
    font-size: 0.5rem;
    @extend .tg-workflow-text;
}
  
.tg-default-approver-byline {
    font-weight: 500;
    font-size: 0.6rem;
    color: #4e86ec;
    @extend .tg-workflow-text;
}
  
.tg-delegated-approver-byline {
    font-weight: 500;
    font-size: 0.6rem;
    color: #8a5ae1;
    @extend .tg-workflow-text;
}
  
.tg-deviation-approver-byline {
    font-weight: 500;
    font-size: 0.6rem;
    color: #311fce;
    @extend .tg-workflow-text;
}
.custom-marker {
    display: flex;
    width: 1rem;
    height: 1rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.p-timeline-event {
    min-height: 110px!important;
}

.p-timeline-event-opposite {
    display: none!important;
}