
.legend-container{
    @extend .d-flex, .justify-content-end,
     .align-items-center, .mt-3, .gap-3;
}
  
.legend-element{
    @extend .d-flex, .align-items-center, .gap-2;
}

.legend-text{
    font-weight: 600;
}

.legend-marker{
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.transferred{
    background-color: #A0E3C4;
}

.added{
    background-color: #9EC5FE;
}

.deducted{
    background-color: #F7C5C5;
}

.total-amount{
    background-color: #a6c2b5;
    // text-color: #216e4b
}
.approved-in-po{
    background-color: #9dc2f8;
    // color: #295494
}
.in-process-of-po{
    background-color: #ece1be;
    // color: #887844;
}
.balance{
    background-color: #e1f495;
    // color: #698207;
}