.p-steps .p-steps-item {
    & .p-menuitem-link{
        cursor: pointer;
        &:not(.p-disabled):focus{
            box-shadow: none !important;
        }
    }
    &.p-highlight{
        & .p-steps-number{
            color: white !important;
            background-color: var(--secondary-color) !important;
            border-color: var(--secondary-color) !important;
        }
        & .p-steps-title{
            color: #6c757d !important;
            font-weight: 400 !important;
        }
    }
}

.p-steps .p-steps-item{
    &:first-child::before{
        left: 50%;
        width: 50%;
    }
    &:last-child::before{
        left: 0%;
        width: 50%;
    }
}

.p-steps-title{
    font-size: 14px;
}
