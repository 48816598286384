.tg-currency-input{
    border: 1px solid #ccc;
    padding: 2px 11px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #ccc;
}

.tg-curreny-input-field{
    border-top-left-radius: 0px;
    border-left: none;
    border-bottom-left-radius: 0;
    font-size: .8rem;
    border: 1px solid #ccc;
    border-radius: .15rem;
    color: #0b204b;
    width: 100%;
    padding: .3rem .4rem;
    font-weight: 500;
}