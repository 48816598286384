.bold-font-16{
    font-size: 16px;
    font-weight: 600;
}

.row-gap-3{
    row-gap: 1rem;
}

.flex-between-v-center{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-between{
    display: flex;
    justify-content: space-between;
}

.flex-vh-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-v-center{
    display: flex;
    align-items: center;
}

.flex-v-center-h-end{
    display: flex;
    align-items: center;
    justify-content: end;
}

.flex-gap-2{
    display: flex;
    gap: 0.5rem;
}

.flex-gap-3{
    display: flex;
    gap: 0.75rem;
}

.flex-gap-4{
    display: flex;
    gap: 1.25rem;
}

// Queue

.queue-heading{
    @extend .my-0, .me-3;
}

.queue-plus-button{
    @extend .my-0, .h5;
}

// .p-dialog-mask{
//     background-color: #0000007a;
// }