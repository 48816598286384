.tg-expense-detail-row {
    // font-weight: 500;
    // letter-spacing: 0.06rem;
    font-size: 0.8rem;
}

.tg-validation-text {
    font-size: 0.65rem;
    font-weight: 500;
    letter-spacing: 0.04rem;
    margin-top: 0.08rem;
}

.tg-timestamp-text{
    @extend .tg-validation-text;
    font-size: 0.75rem;
}

.p-datatable .p-datatable-header{
    background-color: unset !important;
    border-top: unset !important;
}