.p-accordion-header-link:focus{
    box-shadow: none !important;
}
  
.p-accordion-content{
    padding: 0.25rem !important;
}
  
.p-accordion-tab{
    padding-bottom: 1rem !important;
}